import React from 'react';
import './styles.css';
import { useGoogleLogin } from '@react-oauth/google';
import { autenticarParceiroSocial } from '../../../store/modules/autenticacao/actions';
import { useDispatch } from 'react-redux';
import { atualizarPerfil } from '../../../store/modules/parceiro/actions';

const GoogleSync = () => {
  const dispatch = useDispatch();
  const responseGoogle = (e) => {
    console.log(`googleAuthentication`, e, {
      index: 'googleOauth',
      social_token: e.access_token,
    });
    dispatch(atualizarPerfil({ googleOauth: e.access_token }));
  };

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      console.log(tokenResponse);
      responseGoogle(tokenResponse);
    },
  });

  return (
    <button
      type={'button'}
      onClick={() => login()}
      className={'btn-primario btn-primario3'}
    >
      <i className={'fab fa-google'}></i> Login com Google
    </button>
  );
};

export default GoogleSync;
